import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_BR } from "@ag-grid-community/locale"
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { Search } from '@mui/icons-material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DevolucaoModal from "./devolucao_modal"

const ConsultaDevol = () => {
  const [rowData, setRowData] = useState();
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const codAgendamento = searchParams.get('codAgendamento'); 
  const openModal = searchParams.get('openModal');
  const gridRef = useRef();

  useEffect(() => {
    handleGetEntidade();
  }, []);

  useEffect(() => {
    if (openModal !== null) {
      const modalElement = document.getElementById('modal2');
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
      modalElement.setAttribute('aria-modal', 'true');
      modalElement.setAttribute('role', 'dialog');
      document.body.classList.add('modal-open');
      const backdropElement = document.createElement('div');
      backdropElement.classList.add('modal-backdrop', 'fade', 'show');
      document.body.appendChild(backdropElement);
    }
  }, [openModal]);
  
  const handleGetEntidade = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/devolucao`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setRowData(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  const parseDate = (dateString) => {
    if (!dateString) return null;
    console.log(dateString)
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/');
    const [hours, minutes, seconds] = timePart.split(':');
  
    // Mês em JavaScript é 0-indexed, então subtraímos 1
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };
  
  const formatDate = (date) => {
    if (!date || isNaN(date.getTime())) return 'Data Inválida';
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  useEffect(() => {
    if (codAgendamento !== null) {
      const modalElement = document.getElementById('modal2');
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
      modalElement.setAttribute('aria-modal', 'true');
      modalElement.setAttribute('role', 'dialog');
      document.body.classList.add('modal-open');
      const backdropElement = document.createElement('div');
      backdropElement.classList.add('modal-backdrop', 'fade', 'show');
      document.body.appendChild(backdropElement);
    }
  }, [codAgendamento]);


  const colDefs = [
    { field: "numeroAgendamento", headerName: "NºAgend", filter: true,floatingFilter:true, width: 120 },
    { field: "numeracaoContainer", headerName: "Container", filter: true, floatingFilter:true, width: 120 },
    { field: "codArmador", headerName: "Armador", filter: true, floatingFilter:true, width: 150 },
    { field: "codResp", headerName: "Importador", filter: true, floatingFilter:true, width: 200 },
    { field: "codTipo", headerName: "Tipo de Container", filter: true, floatingFilter:true, width: 170 },
    { field: "id_transp", headerName: "Transportadora", filter: true, floatingFilter:true, width: 250 },
    { field: "dataHoraPrevista", headerName: "Data Prevista", filter: true, floatingFilter:true, width: 160 ,valueGetter: (params) => {
      return parseDate(params.data.dataHoraPrevista);
    },
    valueFormatter: (params) => {
      return formatDate(params.value);
    },
    sort: 'desc',
    sortingOrder: ['asc', 'desc'],
    comparator: (valueA, valueB) => {
      if (!valueA && !valueB) return 0;
      if (!valueA) return 1;
      if (!valueB) return -1;
      return valueA.getTime() - valueB.getTime();
    }},
    {
      field: "codAgendamento", 
      headerName: "", 
      filter: false, 
      width: 155, 
      cellRenderer: (params) => (
        <Link type="button" to={`/consulta_devolucao?codAgendamento=${params.data.codAgendamento}`}
        className="btn mx-2 btn-primary"
        >Ver Detalhes</Link>
      )
    }
  ];

  return (
    <>
      <Navbar></Navbar>
      <div class="row align-items-start g-1">
        <div class="col-12">
          <div className="box-center">
            <div className="card col-md-12 mx-auto">
              <div className="card-body pt-2">
                <div className="row d-flex justify-content-between">
                  <legend className="form-label mt-3 col">Devoluções de Importação</legend>
                  <div className="col-auto mt-3">
                    <Link type="button" className="btn mx-2 btn-primary" data-bs-target="#modal2" data-bs-toggle="modal" >Nova Solicitação</Link>
                  </div>
                </div >
                <div><hr className="divider" /></div>
                  <div className="box-center1">
                    <div className=" rounded card">
                      <div className="card col-md-12">
                        <div className="ag-theme-quartz" style={{ height: 400 }}>
                          <AgGridReact
                            localeText={AG_GRID_LOCALE_BR}
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={colDefs}
                            rowSelection="unique"
                            pagination="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <DevolucaoModal/>
    </>
  );
};

export default ConsultaDevol;
import React, { useEffect, useState, useRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_BR } from "@ag-grid-community/locale"
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const Dashboards2 = () => {
  const [rowData, setRowData] = useState();
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [totals, setTotals] = useState([]);
  const gridRef = useRef();

  useEffect(() => {
    handleGetAgendamentos();
  }, []);

  useEffect(() => {
    // Calcula os totais inicialmente quando os dados são carregados
    if (gridRef.current && gridRef.current.api) {
      calculateTotals();
    }
  }, [rowData]);

  const handleGetAgendamentos = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/agendamento3`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setRowData(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  const onFilterChanged = useCallback(() => {
    calculateTotals();
  }, []);
  
  const calculateTotals = useCallback(() => {
    let totalQuant = 0;
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      totalQuant += parseFloat(node.data.quant) || 0;
    });

    setTotals([{
      agendamentodata: '',
      codArmador: '',
      codTipo: '',
      cargotype: 'TOTAL',
      quant: totalQuant,
    }]);
  }, []);

  const applyMSCFilter = useCallback(() => {
    const instanceArmador = gridRef.current.api.getFilterInstance('codArmador');
    instanceArmador.setModel({
      type: 'contains',
      filter: 'MSC'
    });
    gridRef.current.api.onFilterChanged();
  }, []);
  
  const applyHPLFilter = useCallback(() => {
    const instanceArmador = gridRef.current.api.getFilterInstance('codArmador');
    instanceArmador.setModel({
      type: 'contains',
      filter: 'HAPAG'
    });
    gridRef.current.api.onFilterChanged();
  }, []);

  const applyMSKFilter = useCallback(() => {
    const instanceArmador = gridRef.current.api.getFilterInstance('codArmador');
    instanceArmador.setModel({
      type: 'contains',
      filter: 'MAERSK'
    });
    gridRef.current.api.onFilterChanged();
  }, []);

  const applyONEFilter = useCallback(() => {
    const instanceArmador = gridRef.current.api.getFilterInstance('codArmador');
    instanceArmador.setModel({
      type: 'contains',
      filter: 'ONE'
    });
    gridRef.current.api.onFilterChanged();
  }, []);

  const applyP2Filter = useCallback(() => {
    const instanceArmador = gridRef.current.api.getFilterInstance('idPatio');
    instanceArmador.setModel({
      type: 'contains',
      filter: '2'
    });
    gridRef.current.api.onFilterChanged();
  }, []);
  const applyP3Filter = useCallback(() => {
    const instanceArmador = gridRef.current.api.getFilterInstance('idPatio');
    instanceArmador.setModel({
      type: 'contains',
      filter: '3'
    });
    gridRef.current.api.onFilterChanged();
  }, []);
  const applyP6Filter = useCallback(() => {
    const instanceArmador = gridRef.current.api.getFilterInstance('idPatio');
    instanceArmador.setModel({
      type: 'contains',
      filter: '6'
    });
    gridRef.current.api.onFilterChanged();
  }, []);
  const applyP7Filter = useCallback(() => {
    const instanceArmador = gridRef.current.api.getFilterInstance('idPatio');
    instanceArmador.setModel({
      type: 'contains',
      filter: '7'
    });
    gridRef.current.api.onFilterChanged();
  }, []);
  const applyPMDFilter = useCallback(() => {
    const instanceArmador = gridRef.current.api.getFilterInstance('idPatio');
    instanceArmador.setModel({
      type: 'contains',
      filter: 'Marginal'
    });
    gridRef.current.api.onFilterChanged();
  }, []);
  const applyPGJAFilter = useCallback(() => {
    const instanceArmador = gridRef.current.api.getFilterInstance('idPatio');
    instanceArmador.setModel({
      type: 'contains',
      filter: 'Guaruj'
    });
    gridRef.current.api.onFilterChanged();
  }, []);
  const applyPRCFilter = useCallback(() => {
    const instanceArmador = gridRef.current.api.getFilterInstance('idPatio');
    instanceArmador.setModel({
      type: 'contains',
      filter: 'Rio'
    });
    gridRef.current.api.onFilterChanged();
  }, []);

  const clearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.onFilterChanged();
  }, []);

  const columns = [
    { field: "agendamentodata", headerName:"Data Agend.", filter: true, floatingFilter: true, width:160},
    { field: "idPatio", headerName:"Pátio", filter: true, floatingFilter: true, width:100},
    { field: "codArmador", headerName: "Armador", filter: true, floatingFilter: true, width: 150 },
    { field: "codTipo", headerName: "Tipo de Container", filter: true, floatingFilter: true, width: 170 },
    { field: "cargotype", headerName:"Padrão", filter: true, floatingFilter: true, width: 130},
    { field: "quant", headerName: "Quantidade", filter: true, floatingFilter: true, width: 120},
  ];

  return (
    <>
      <Navbar />
      <div className="box-center1">
        <div className="rounded card">
          <div className="card col-md-12">
            <div className="row">
              <div type= "button" className="col-auto btn btn-primary ms-4 mb-2 mx-2 mt-2" onClick={applyHPLFilter}>HAPAG</div>
              <div type= "button" className="col-auto btn btn-primary mb-2 mx-2 mt-2" onClick={applyMSKFilter}>MAERSK</div>
              <div type= "button" className="col-auto btn btn-primary mb-2 mx-2 mt-2" onClick={applyMSCFilter}>MSC</div>
              <div type= "button" className="col-auto btn btn-primary mb-2 mx-2 mt-2" onClick={applyONEFilter}>ONE</div>
              <div type= "button" className="col-auto btn btn-warning mb-2 mx-2 mt-2" onClick={clearFilters}>Limpar Filtros</div>
            </div>
            <div className="row">
              <div type= "button" className="col-auto btn btn-info ms-4 mb-2 mx-2 mt-2" onClick={applyP2Filter}>Pátio 2</div>
              <div type= "button" className="col-auto btn btn-info mb-2 mx-2 mt-2" onClick={applyP3Filter}>Pátio 3</div>
              <div type= "button" className="col-auto btn btn-info mb-2 mx-2 mt-2" onClick={applyP6Filter}>Pátio 6</div>
              <div type= "button" className="col-auto btn btn-info mb-2 mx-2 mt-2" onClick={applyP7Filter}>Pátio 7</div>
              <div type= "button" className="col-auto btn btn-info mb-2 mx-2 mt-2" onClick={applyPMDFilter}>Pátio Marginal</div>
              <div type= "button" className="col-auto btn btn-info mb-2 mx-2 mt-2" onClick={applyPGJAFilter}>Pátio Guarujá</div>
              <div type= "button" className="col-auto btn btn-info mb-2 mx-2 mt-2" onClick={applyPRCFilter}>Pátio Rio Claro</div>
            </div>
            <div className="ag-theme-quartz" style={{ height: 550 }}>
              <AgGridReact
                ref={gridRef}
                localeText={AG_GRID_LOCALE_BR}
                rowData={rowData}
                columnDefs={columns}
                pagination={true}
                onFilterChanged={onFilterChanged}
                pinnedBottomRowData={totals}
                getRowStyle={(params) => {
                  if (params.node.rowPinned === 'bottom') {
                    return { fontWeight: 'bold', backgroundColor: '#d9e6ff', fontSize: '1.2em', borderRadius: '8px'};
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboards2;